import React from "react"
import Layout from "@components/kaigai-fx/layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { useLocation } from "@reach/router"

// Import SVG
import icon_t_SVG1 from '@images/kaigai-fx/top_t_icon1.svg';
import icon_post_SVG3 from '@images/kaigai-fx/post_icon3.svg';

// WP CSS
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"

// コンポーネント呼び出し
import { GraphqlArray } from "@components/kaigai-fx/graphql-data-post.js"; // 記事情報取得・形成
import { SearchCategList } from "@components/kaigai-fx/search-categList.js"; // カテゴリ検索機能
import { RelatedContents } from "@components/kaigai-fx/related-article.js"; // 関連記事取得・形成
import { FixedFoot } from "@components/kaigai-fx/footer";
import { WordSearch } from "@components/kaigai-fx/word-search.js";
import { ProfileContents } from "@components/kaigai-fx/profile.js";
import { SeoContents } from "@components/kaigai-fx/seo-data.js" // Seo情報設定

const SearchResults = () => {
  const indexUrl = '/kaigai-fx/'
  const searchList = SearchCategList().Main; // カテゴリ検索リスト
  const wordSearchContentPC = WordSearch().PC; // ワード検索PC
  const wordSearchContentMB = WordSearch().MB; // ワード検索MB
  
  // // graphql-data-post.jsから記事データ呼び出し
  const allPostData = GraphqlArray('', 'search')
  let setArray = allPostData.allData;

  // Seo情報設定
  const pathName = 'search-results';
  const seoTag = SeoContents({ Path:pathName });

  const location = useLocation()
  const urlItem = location.href;
  let item = '#';
  let name;

  let index1, status;
  if ( urlItem !== undefined ){
    index1 = urlItem.indexOf(item);
  } else {
    index1 = -1;
  }

  if (index1 !== -1 ) { // 検索結果ありの場合
    let check = decodeURI(urlItem.substring(index1 + 1));
    name = check; // 検索ワードセット
    let searchWord;
    let resultArray = [];
    for ( let i = 0; i < setArray.length; i++) {
        searchWord = setArray[i].Title + setArray[i].Content;
        if ( searchWord.toLowerCase().indexOf(check.toLowerCase()) !== -1) {
          resultArray.push(setArray[i])
        } 
      }
    setArray = resultArray;
    status = 'loadcomp'
    name = "「" + name + "」の検索結果：" + setArray.length + "件"; // 表示名作成
  } else if ( index1 === -1 ) { // 検索結果がない場合
    name = `検索中`
    status = 'loading'
    setArray = [];
  }

  // 関連記事取得(components/related-article.js)
  let relatedTitle, relatedList;
  if ( setArray[0] !== undefined ) { // 記事のないカテゴリ生成時のエラー回避
    relatedTitle = setArray[0].Title; // 仮で一番目の記事の関連を取得
    relatedList = RelatedContents( allPostData, relatedTitle );
  } 

  // パンくず設定
  let topicCategPath;
  if ( status !== 'loading') {
    topicCategPath =  <>
                        <img className="pr2" alt="" src={icon_post_SVG3}/>
                        <a href={indexUrl} className="px-2 hover:text-gray-400">ホーム</a>
                        <span className="pr-2 mx-0 my-auto inset-y-0 md:m-0">＞</span>
                        <span className="pr-2">{name}</span>
                      </>;
  } 


  // タグ表示設定
  var topicTags = [];
  setArray.map(function( value ) {// 表示結果の全タグ取得
    return topicTags.push(...value.Tags); 
  });
  var setTopicTags = Array.from(new Set(topicTags)); // 重複削除
  var topicTagsList = setTopicTags.map((result) => {
    return  <>
              <a href={indexUrl + "tags/" + result + "/"} className="mb-2 mr-2 text-white bg-[#36A7C3] text-[9px] rounded px-2 py-1 text-center">{result}</a>
            </>            
  });

  // 記事一覧生成
  const pickupList = setArray.map((result) => {
    return  <>
              <li className="md:w-1/3" key={result['Uri']} style={{ aspectRatio: "1/1" , marginBottom:15 ,padding:10}}>
                <article className="post-list-item !m-0 " style={{ height: '100%' }} itemScope itemType="http://schema.org/Article">
                  <a href={indexUrl + result.Title + "/"} itemProp="url" className="Campaign w-[100%]">
                    <div className="w-full" style={{ height: '55%' }}>
                      {/* 画像部分 */}
                      <GatsbyImage className="block w-full h-full rounded-blog overflow-hidden" image={result['Image']}
                        alt={result.AltText ? result.AltText : ""} as={`figure`} style={{ aspectRatio: "25/14" , height: '100%' }}
                      />
                    </div>
                    {/* タイトル部分 */}
                    <div className="bg-white w-full text-left" style={{ height: '45%' }}>
                    <p className="CampP md:text-xs text-[15px] font-bold mb-4 py-2 text-left " style={{ height: '8%' }}>{result.Title}</p>
                      <p className="text-[11px] text-left  md:text-[10px] pt-4" >{parse(result.Excerpt)}</p>
                    </div>
                  </a>
                </article>
              </li>
            </> ;
  });

  // プロフィール
  const profilePC = ProfileContents().PC 
  const profileMB = ProfileContents().MB

  const fixedItem = FixedFoot( 'archive', SearchCategList().Sub ); // 固定フッター

  return (
    <>
      <Layout>
        {seoTag}
        <div className="md:max-w-5xl md:flex justify-between mx-auto pt-5 md:pt-12"> 
          <div className="max-w-4xl pb-5 px-4 mb-10 md:pb-0 md:mb-0 w-full">
            <div className="text-left mb-3 mx-auto flex items-center">
              <p><img  alt="" src={icon_t_SVG1}/></p>
              <h1 className="ml-5 font-semibold tracking-wide text-[20px] md:text-[26px] text-[#36A7C3] table" itemProp="headline">{ name }</h1>
            </div>

            {/* パンくず・タグ */}
            <p className="text-[12px] mb-[20px] md:mb-[30px]">
              <div className="flex mb-2 md:mb-3">
                {topicCategPath}
              </div>
              <div className="flex flex-wrap">{topicTagsList}</div>
            </p>

            <ol style={{ listStyle: `none` }}>
              <div  className="mx-auto md:flex max-w-xl md:flex-wrap items-center md:max-w-5xl justify-start">
                {pickupList}
              </div>
            </ol>

          </div>

          <div className="column2_filed px-4">
            {/* PCプロフィール */}
            {profilePC}

            {/*カテゴリ検索 */}
            <div>
              {searchList}
            </div>

            {/*ワード検索 */}
            {wordSearchContentPC}
            
            <div className="md:sticky">
              {/*関連記事 */}
              <h3 className="text-[#36A7C3] mt-13 md:mt-10 mb-5 font-semibold flex">関連記事</h3>
              <div className="flex-wrap justify-around mb-10 md:block flex">
                {relatedList}
              </div>
            </div>
          </div>
        </div>
        <div>
          {/*ワード検索 */}
          {wordSearchContentMB}

          {/* MBプロフィール */}
          {profileMB}

        </div>
        {/* 固定フッター */}
        {fixedItem}
      </Layout>
    </>
  )
}
export default SearchResults

export const pageQuery = graphql`
  query SearchPageQuery {
    allWpKaigaiFx(sort: {fields: date, order: DESC}) {
      edges {
        node {
          date(formatString: "YYYY/MM/DD")
          title
          content
          fxCategories {
            nodes {
              name
            }
          }
          fxTags {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`